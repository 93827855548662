import { default as _91slug_93SLEUvcCTJmMeta } from "/home/forge/b2b.burtekkayis.com/pages/[slug].vue?macro=true";
import { default as addressCmsJC7G7e8Meta } from "/home/forge/b2b.burtekkayis.com/pages/account/address.vue?macro=true";
import { default as _91id_93NIozXzlVVXMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93dLOkY0djiSMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexQYfeGBEkfIMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/orders/index.vue?macro=true";
import { default as review5ulZYPCJAEMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/review.vue?macro=true";
import { default as _91id_93Wl4HWUeCe4Meta } from "/home/forge/b2b.burtekkayis.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as index8b4Q4S2NkzMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/statement/index.vue?macro=true";
import { default as user8DOtxqFfWHMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/user.vue?macro=true";
import { default as wishlistIkHK7T0efQMeta } from "/home/forge/b2b.burtekkayis.com/pages/account/wishlist.vue?macro=true";
import { default as cartY0JQVITOraMeta } from "/home/forge/b2b.burtekkayis.com/pages/cart.vue?macro=true";
import { default as indexbJDk79HnXUMeta } from "/home/forge/b2b.burtekkayis.com/pages/checkout/index.vue?macro=true";
import { default as payment68KhSfWMpwMeta } from "/home/forge/b2b.burtekkayis.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientGCIyYMp2DhMeta } from "/home/forge/b2b.burtekkayis.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/b2b.burtekkayis.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as request4GytnV6fpnMeta } from "/home/forge/b2b.burtekkayis.com/pages/customer/request.vue?macro=true";
import { default as successmTqnXd7UsMMeta } from "/home/forge/b2b.burtekkayis.com/pages/customer/success.vue?macro=true";
import { default as iletisimUi7YEvSd5bMeta } from "/home/forge/b2b.burtekkayis.com/pages/iletisim.vue?macro=true";
import { default as indexq0TAIBHfVQMeta } from "/home/forge/b2b.burtekkayis.com/pages/index.vue?macro=true";
import { default as login83x3xwkuv6Meta } from "/home/forge/b2b.burtekkayis.com/pages/login.vue?macro=true";
import { default as offerYCm7kmL4X0Meta } from "/home/forge/b2b.burtekkayis.com/pages/offer.vue?macro=true";
import { default as status0ljcf9SzRrMeta } from "/home/forge/b2b.burtekkayis.com/pages/order/status.vue?macro=true";
import { default as track1jOmEbfg3KMeta } from "/home/forge/b2b.burtekkayis.com/pages/order/track.vue?macro=true";
import { default as forgotXq9ZFt6AQgMeta } from "/home/forge/b2b.burtekkayis.com/pages/password/forgot.vue?macro=true";
import { default as resetNrpxTBqoNMMeta } from "/home/forge/b2b.burtekkayis.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationz0XPCEdjyRMeta } from "/home/forge/b2b.burtekkayis.com/pages/payment-notification.vue?macro=true";
import { default as summaryWUMwamYYkRMeta } from "/home/forge/b2b.burtekkayis.com/pages/payment/summary.vue?macro=true";
import { default as registerJ0XBUlTdFBMeta } from "/home/forge/b2b.burtekkayis.com/pages/register.vue?macro=true";
import { default as searchA829vUCXo1Meta } from "/home/forge/b2b.burtekkayis.com/pages/search.vue?macro=true";
import { default as locationsaUY4x9mocpMeta } from "/home/forge/b2b.burtekkayis.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93SLEUvcCTJmMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressCmsJC7G7e8Meta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93NIozXzlVVXMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93dLOkY0djiSMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexQYfeGBEkfIMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: review5ulZYPCJAEMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93Wl4HWUeCe4Meta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: index8b4Q4S2NkzMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: user8DOtxqFfWHMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistIkHK7T0efQMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexbJDk79HnXUMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment68KhSfWMpwMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/b2b.burtekkayis.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexq0TAIBHfVQMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: login83x3xwkuv6Meta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerYCm7kmL4X0Meta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotXq9ZFt6AQgMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetNrpxTBqoNMMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerJ0XBUlTdFBMeta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchA829vUCXo1Meta || {},
    component: () => import("/home/forge/b2b.burtekkayis.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/b2b.burtekkayis.com/modules/locationsPage/pages/locations.vue")
  }
]